//Alertify
import 'alertifyjs/build/css/alertify.min.css'
import 'alertifyjs/build/css/themes/bootstrap.css'
import alertify from 'alertifyjs/build/alertify'
alertify.set('notifier', 'delay', 10)

function handleCredentialResponse(response) {
	$.ajax({
		url: 'g-login',
		type: 'POST',
		dataType: 'json',
		data: { credential: response.credential },
	})
		.done(function (res) {
			if (res && res.ac == 'login') {
				if (res.success) {
					let current_url = window.location.href

					if (current_url.search('carrito') != -1) {
						location.href = web_url + 'carrito'
					} else {
						location.href = web_url + 'inicio'
					}
				} else {
					alertify.error(res.msg)
				}
			}
		})
		.fail(function (res) {
			console.log('error', res)
		})
		.always(function () {
			$('.loader').removeClass('active')
		})
}

window.onload = function () {
	google.accounts.id.initialize({
		client_id: document.getElementById('google-client-id').value,
		callback: handleCredentialResponse,
	})
	google.accounts.id.renderButton(
		document.getElementById('google_btn'),
		{ theme: 'outline', size: 'medium' } // customization attributes
	)
	// google.accounts.id.prompt() // also display the One Tap dialog
}
